//* VARIABLES *//

@import url('https://fonts.googleapis.com/css?family=Muli:400,700,700i,900,900i');


@plone-link-color:              #000; //all black
@plone-link-color-on-dark:      #000; //all black
@plone-body-bg:                 #fff; //white

@plone-sitenav-link-hover-bg:   transparent;
@plone-sitenav-bg:              transparent;
@plone-sitenav-hover-bg:        transparent;

@plone-font-family-sans-serif:  "Muli", "Helvetica Neue", Helvetica, Arial, sans-serif; // Roboto seems Plone's DIN
@plone-font-family-condensed:   "Muli", "Arial Narrow", sans-serif; //just on toolbar


@plone-font-weight-light:       400;
@plone-font-weight-regular:     700;
@plone-font-weight-medium:      900;
@plone-font-weight-bold:        900;

@openlab-line-thickness: 3px;

@tile-size: 360px;

#hero-animation {
  & canvas {
    width: 100% !important;
    max-width: 400px;
    height: auto !important;
  }
}

#mobile-logo {
  margin: auto;
  width: 80px;
  height: 80px;
  & img {
    height: inherit;
    width: inherit;
    object-fit: fill;
  }
}

@media (max-width: @plone-screen-sm-min) {
  #hero-animation {
    display: none;
  }
}

@media (min-width: @plone-screen-sm-min) {
  #mobile-logo {
    display: none;
  }
}

@media (max-width: @plone-screen-xs-min) {
  .openlab-listing article .teaser-image img {
    width: 100%;
    height: auto;
  }
  .openlab-listing article .teaser-image {
    float: none;
  }
  .openlab-listing article .teaser-about {
    padding-left: 15px;
  }
}

#openlab-font-base {
  color: #000;
  font-family: @plone-font-family-sans-serif;
}
#openlab-font-mixin {
  .small_head {
    #openlab-font-base;
    font-size: 18px;
    font-weight: 900;
  }
  .small_text {
    #openlab-font-base;
    font-size: 18px;
    font-weight: 600;
  }
}

#openlab-border {
  .top {
    border-top: @openlab-line-thickness solid #000;
  }
  .bottom {
   border-bottom: @openlab-line-thickness solid #000;
  }
  .left {
   border-left: @openlab-line-thickness solid #000;
  }
  .right {
   border-right: @openlab-line-thickness solid #000;
  }
}

.portlet {
    border:none !important;
    > .portletHeader {
        background-color: #fff;
        color: #000;
        border: none;
        #openlab-border.bottom;
    }
}

.documentFirstHeading {
    color:#000;
    font-size: 27px;
    font-weight: 900;
    #openlab-border.bottom;
    margin-bottom: 0;
}

//* SITENAV *//


#portaltab-index_html {
  padding-bottom: 10px;
  background-size: 25px;
  > a {
    font-size:190%;
    font-weight: 900;
  }
}

@media (min-width: 768px) {
  #portaltab-index_html {
    padding-bottom: 0;
    background-position: 4% 50%;
  }
}


.plone-nav {
  &:extend(.clearfix all);
  > li {
    > a {
      color: #000;
      font-weight: 900;
    }
  }
}

.plone-navbar {
  #openlab-border.bottom;
  color: #000;
}

.plone-navbar-toggle {
  // Menu (That option with a word, witch is language-dependant, is to ensure a higher tap ratio on the menu)
  .icon-bar:after {
    content:"Menu";
    color: #000;
  }
}

.plone-navbar-nav {
  > .selected > a {
    &,
    &:hover,
    &:focus {
      color: #000;
    }
  }

  // Uncollapse the nav
  @media (min-width: @plone-grid-float-breakpoint) {
    #openlab-border.left;
    > li {
      #openlab-border.right;
    }
  }
}

/* Original Slick themes*/
@import "slick.css";
@import "slick-theme.css";

/* Slick modifications to have the arrows on the image*/
.slick-prev
{
    left: 5px;
}
.slick-next
{
    right: 5px;
}

.slick-prev,
.slick-next {
    z-index: 20;
}


/* Modifications to allow a slick gallery in hero*/
#hero.principal {
  padding: 0 0;
  margin-bottom: -50px;
  background: none !important;
  background-color: #eee !important;
}

#hero .container {
  padding: 0;
  margin: 0;
  width: 100%;

}

#hero {
  margin-top: 50px;
}
.section-startseite {
  #hero {
    margin-top:0;
  }
}



#above-content-wrapper {
  margin-top: 70px;
}

.plone-toolbar-left-default #hero .container {
  width: 100%;
}

/* Highlight the gallery inside Tinymce*/

.mce-content-body .pat-slick{
  border-left: 8px green solid;
  &:before {
    content: "Gallery";
  }
}

@media (min-width: 1320px) {
  .plone-toolbar-left-expanded .container {
      width: 1046px;
  }
}

@media (min-width: 1112px) {
  .plone-toolbar-left-expanded .container {
      width: 1046px;
  }
}



#mainnavigation-wrapper {
  z-index: 21;
  display: none;
  background-color: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  &.fade {
  }
  &.fixed {
    display: block;
  }
}


.openlab-collection-description p {
  font-family: @plone-font-family-sans-serif;
  font-weight: 600;
  font-size: 23px;
  padding: 0 2em;
  margin-bottom: 35px;
  color: #000;
  @media (max-width: @plone-screen-sm-min) {
    font-size: 18px;
    padding: 0 15px !important;
  }
}


#mainnavigation {
  background-color: #FFF;
}

.tint {
  position: relative;
  margin-right: 0px;
  margin-bottom: 0px;
}



.tint:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  width: @tile-size;
  left: 10px;
  height: @tile-size;
  background: rgba(82, 102, 101, 0.5);
  transition: all .3s linear;
}

.tint_sm:before {
  height: 64px;
  width: 64px;
  left:0;
  z-index:500;
}


.tint:hover:before { background: none; }

.t_projekt:before { background: rgba(51, 152, 152,  0.5); }
.t_netzwerk:before { background: rgba(218, 121, 100, 0.5); }

.template-tiles_view {
  div.entries {
    margin-left: -33px;
    padding-top: 15px;
  }
}

p.discreet {
  font-size: 80% !important;
  color: #444 !important;
  margin-bottom: 5px;
}


.portaltype-subjectarea {
  div.entries {
    margin-left: -33px;
    padding-top: 15px;
  }
  .documentFirstHeading {
    margin-top: 2.5ex;
  }
}



.ol_tile {
  width: 353px;
  margin-bottom: 20px;
  padding-left: 10px;
  margin-left: 33px;
  padding-right: 10px;
  @media (max-width: @plone-screen-sm-min) {
    margin-left: auto;
    margin-right: auto;
  }
  img {
    width: @tile-size;
    height: @tile-size;
  }
  .tilecontent {
    margin-top:-339px;
    position: absolute;
    background: rgba(255,255,255,0.7);
    margin-left: 19px;
    padding: 3px;
    height: 319px;
    width: 319px;
    .title {
      font-size: 27px;
      font-weight: 900;
      margin-bottom: 0px;
    }
    .smdate {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .txt {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0px;
    }
    &:hover {
      background: rgba(255,255,255,0);
      transition: all .3s linear;
      > * {
        display: none;
      }
    }
  }
}


/* Footer adaptations */
#portal-footer-wrapper {
  color: #000;
  background-color: #fff;
  text-align: left;
  .openlab-footer {
    padding-top: 20px;
    #openlab-border.top;
    #openlab-border.bottom;
    @media (max-width: @plone-screen-sm-min) {
      text-align: center;
    }
  }
  p {
    border-bottom: 0;
    font-weight: 700;
  }
  h3 {
    margin-top: 0px;
    font-weight: 900;
  }
  a {
    font-size: 18px;
    font-weight: 700;
  }
}


/* Calendar/Events portlet modifications */

.portletEvents.openlab-calendar {
  box-shadow: none;
  border: none;
  .documentFirstHeading {
    font-size: 27px;
    font-weight: 900;
    border: 0;
  }
  .portletHeader {
    padding: 0;
  }
  .portletFooter {
    display: none;
  }
  a{
    color: #000;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .portletContent {
    .portletItem,
    .portletItemDetails {
      color: #000;
      font-size: 18px;
      display: inline-block;
    }
    .portletItem {
      width: 100%;
      #openlab-border.bottom;
    }
  }
  .portletContent > ul li{
    background: #fff;
    &:hover {
      background: #fff;
    }
    a {
      color: #000;
      font-size: 18px;
      padding-left: 0px;
      margin-top: 0px;
      padding-bottom: 5px;
      margin-bottom: 0px;
    }
    img {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

div.eventdetails {
  padding-left: 74px;
}

.portlet .portletContent > ul .portletItemDetails {
  padding-left:0;
}

abbr[title] {
  cursor:default;
  border:none;
  text-decoration: none;
}

/* Openlab listing view */
#content-core .openlab-listing a {
  border: 0;
}
.openlab-listing {
  a,
  a:focus,
  a:hover {
    text-decoration: none;
  }
  article {
    #openlab-border.bottom;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .teaser-image {
    margin-left: 15px;
    margin-right: 15px;
    float: left;
  }
  .teaser-about {
    padding-left: 160px;
    #openlab-font-mixin.small_head;
    p {
      #openlab-font-mixin.small_text;
    }
  }
}


#portaltab-openlab-net-das-netzwerk {
  &.selected,
  &:hover {
    background-image: url('../netzwerk_bg.png');
    background-repeat: no-repeat;
    background-position: 5% 50%;
    background-size: 70px;
  }
}

#portaltab-openlab-net-make-science {
  &.selected,
  &:hover {
    background-image: url('../make_science_bg.png');
    background-repeat: no-repeat;
    background-position: 5% 50%;
    background-size: 70px;
  }
}


@media (min-width: 768px) {
  #portaltab-openlab-net-das-netzwerk {
    &.selected,
    &:hover {
      background-position: 50% 50%;

    }
  }

  #portaltab-openlab-net-make-science {
    &.selected,
    &:hover {
      background-position: 50% 50%;
    }
  }
}

.event.summary {
  float: none;
  clear: none;
}


/* Openlab detail view */

.openlab-detail, .additional-info, .additional_infos {
  .openlab-text {
    #openlab-font-mixin.small_text;
  }
  dt {
    #openlab-font-mixin.small_head;
  }
  dd {
    #openlab-font-mixin.small_text;
    margin-bottom: 5px;
  }
  .event.summary {
    box-shadow: none;
    padding: 0;
    li {
      display: inline;
      #openlab-font-mixin.small_text;
      line-height: @plone-line-height-base;
      margin-bottom: 5px;
      strong {
        #openlab-font-mixin.small_head;
      }
      a:hover,
      a:focus {
        text-decoration: none;
      }
    }
    .timezone {
      display: none;
    }
  }

}

h3 {
  &.title {
    color: black;
  }
}


#portaltab-index_html {
  padding-bottom: 0;
  a {
    display: block;
    float:left;
    padding-left: 5px;
  }
  canvas {
    width: 45px !important;
    height: 45px !important;
  }
}


@media (min-width: 768px) {
  #portaltab-index_html {
    a {
      float:right;
    }
  }
}

#mainnavigation {
  background-color: #eee;
}

#content-core {
  font-weight: 600;
}



.pfg-form {
  hr {
    display: none;
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
}


.pfg-form fieldset {
  background-color: white;
  padding: 5px 20px 5px 20px;
  border: 1px solid #ccc; 
}

.pfg-form .ArchetypesSelectionWidget {
  background-color: #fafafa;
  padding: 5px 10px 5px 10px;
  label {
    line-height: 33px;
    padding:0;
    margin:0;
  }
  select {
    float:right;
    width: 5em;
  }
}

.portaltype-formfolder #content-core {
  margin-top:15px;
}


.formControls {
  background-color: transparent;
  border-top: none;
  box-shadow: none;
  padding:0;
  padding-top: 10px;
  padding-bottom: 10px;
}


body {
  color:#000;
  font-weight: 600;
}

.documentDescription {
  color: #000;
    padding-left: 6px;
}

strong {
  font-weight: 900;
}


#content-core, #viewlet-below-content-body,  #viewlet-above-content-body {
  padding-left: 6px;
  font-size: 18px;
}

.accordionviewlet {
  margin-top: 15px;
  border-top: 1px solid black;
}

.actitle {
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.actitle strong {
  cursor: pointer;
}

.accordion {
  border-bottom: 1px solid black;
}

.actext {
 display: none;
}
@import url('https://fonts.googleapis.com/css?family=Inconsolata:700');

body pre {
  background-color: transparent;
  border: none;
  padding:0;
  font-family: 'Inconsolata', monospace;
}

.openlab-footer img {
  margin-right: 20px;
  margin-bottom: 20px;
}

h3.title {
  padding-left: 6px;
}




