@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/muli/v22/7Aujp_0qiz-afTfcIyoiGtm2P0wG03Z04eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: italic;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/muli/v22/7Aujp_0qiz-afTfcIyoiGtm2P0wG0zh04eqVxg.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/muli/v22/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk30e0.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/muli/v22/7Aulp_0qiz-aVz7u3PJLcUMYOFkpl0k30e0.ttf) format('truetype');
}
@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 900;
  src: url(https://fonts.gstatic.com/s/muli/v22/7Aulp_0qiz-aVz7u3PJLcUMYOFlnl0k30e0.ttf) format('truetype');
}
